
import Card from "@/components/base/card/Card"
import { mdiOpenInNew } from "@mdi/js"

export default {
    name: "CardBlok",
    components: { Card, Button: () => import("@/components/base/Button") },
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    computed: {
        background() {
            const backgrounds = []
            if (this.blok.overlayGradient) {
                backgrounds.push(this.blok.overlayGradient)
            }
            if (this.blok.background?.length > 0) {
                backgrounds.push(
                    ...this.blok.background.map((bg) => bg.filename)
                )
            }
            if (backgrounds.length === 0) return undefined
            return backgrounds
        },
        mobileBackground() {
            const backgrounds = []
            if (this.blok.overlayGradient) {
                backgrounds.push(this.blok.overlayGradient)
            }
            if (this.blok.mobileBackground?.length > 0) {
                backgrounds.push(
                    ...this.blok.mobileBackground.map((bg) => bg.filename)
                )
            } else if (this.blok.background?.length > 0) {
                backgrounds.push(
                    ...this.blok.background.map((bg) => bg.filename)
                )
            }
            if (backgrounds.length === 0) return undefined
            return backgrounds
        },
        showExternalLink() {
            return this.blok.link && this.blok.externalIcon
        },
        isDark() {
            return this.blok.dark
        },

        styles() {
            const style = {}
            if (this.blok.customStyles) {
                for (const entry of this.blok.customStyles.split(";")) {
                    const [key, value, ...rest] = entry.split(":")
                    if (rest?.length > 0) {
                        console.warn(
                            "Too many values in CSS string:",
                            entry,
                            rest
                        )
                    }
                    style[key] = value
                }
            }
            if (this.blok.aspectRatio) {
                style["aspectRatio"] = this.blok.aspectRatio
            }
            if (this.$breakpoint.smAndDown && this.blok.mobileAspectRatio) {
                style["aspectRatio"] = this.blok.mobileAspectRatio
            }
            if (this.blok.maxHeight) {
                style["maxHeight"] = this.blok.maxHeight
            }
            return style
        },
        contentClasses() {
            return {
                "d-flex flex-column justify-end":
                    this.blok.alignment === "bottom",
            }
        },
        overlayStyles() {
            if (!this.blok.overlayStyles || this.blok.overlayStyles === "") {
                return {
                    transform: "scale(1.1)",
                }
            } else {
                return this.blok.overlayStyles
            }
        },
    },
    icons: {
        mdiOpenInNew,
    },
}
